import React from 'react';
import '../../App.css';
import IntroPic from '../Intropic';

function Home() {
  return (
    <>
      <IntroPic />
    </>
  );
}

export default Home;